// Imports
//=require svg4everybody/dist/svg4everybody.min.js
//=require jquery/dist/jquery.min.js
//=require jquery-validation/dist/jquery.validate.min.js
//=require jquery-mask-plugin/dist/jquery.mask.min.js
//=require jquery-countto/jquery.countTo.js

svg4everybody();

// Restricts input for the given textbox to the given inputFilter.
function setInputFilter(textbox, inputFilter) {
    ['input', 'keydown', 'keyup', 'mousedown', 'mouseup', 'select', 'contextmenu', 'drop'].forEach(function (event) {
        textbox.addEventListener(event, function () {
            if (inputFilter(this.value)) {
                this.oldValue = this.value;
                this.oldSelectionStart = this.selectionStart;
                this.oldSelectionEnd = this.selectionEnd;
            } else if (this.hasOwnProperty('oldValue')) {
                this.value = this.oldValue;
                this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
            }
        });
    });
}

// DOM
var MyApp = {
    onlyNumbers: function () {
        $('.only-numbers').each(function () {
            setInputFilter(this, function (value) {
                return /^[0-9]*$/.test(value);
            });            
        });
    },
    onlyLetters: function () {
        $('.only-letters').each(function () {
            setInputFilter(this, function (value) {
                return /^[a-zA-ZáéíóúÁÉÍÓÚ\'´ñÑäëïöüÄËÏÖÜ \-]*$/.test(value);
            });
        });
    },
    onlyAlphaNumeric: function () {
        $('.only-alpha-numeric').each(function () {
            setInputFilter(this, function (value) {
                return /^[0-9a-zA-Z]*$/.test(value);
            });
        });
    },
    onlyPhone: function () {
        $('.only-phone').each(function () {
            setInputFilter(this, function (value) {
                return /^[0-9\-]*$/.test(value);
            });
        });
        $('.only-phone').mask('000-000-0000');
    },
    onlyDate: function () {
        $('.only-date').each(function () {
            setInputFilter(this, function (value) {
                return /^[0-9\/]*$/.test(value);
            });
        });
    },
    form: function () {
        $.validator.methods.email = function (value, element) {
            if (value == '')
                return true;

            var regex = /^([a-zA-ZñÑ0-9_\.\-\+])+\@(([a-zA-ZñÑ0-9\-])+\.)+([a-zA-ZñÑ0-9]{2,4})+$/;
            if (!regex.test(value)) {
                return false;
            } else {
                return true;
            }
        }

        jQuery.validator.addMethod('date', function (value, element) {
            return this.optional(element) || /^((0?[1-9])|1[012])\/(([0-2][0-9])|([1-9])|(3[0-1]))\/(19|20)[0-9]{2}$/.test(value);
        }, 'Please enter a valid date.');

        jQuery.validator.addMethod('over16', function (value, element) {
            currentYear = new Date().getFullYear();
            value = currentYear - (value.substr(value.length - 4));
            if (value >= 16) {
                return true;
            } else {
                return false;
            }
        }, 'You must be at least 16 years old.');

        jQuery.validator.addMethod('under100', function (value, element) {
            currentYear = new Date().getFullYear();
            value = currentYear - (value.substr(value.length - 4));
            if (value < 100) {
                return true;
            } else {
                return false;
            }
        }, 'Please enter a valid date.');

        $('.page-form').validate({
            errorClass: 'page-form__error',
            validClass: 'page-form__valid',
            rules: {
                phone: {
                    maxlength: 12,
                    minlength: 12
                },
                phoneReferral: {
                    maxlength: 12,
                    minlength: 12
                }
            },
            messages: {
                phone: {
                    maxlength: 'You must enter 10 digits',
                    minlength: 'You must enter 10 digits'
                },
                phoneReferral: {
                    maxlength: 'You must enter 10 digits',
                    minlength: 'You must enter 10 digits'
                }
            },
            submitHandler: function (form) {
                $(form).addClass('page-form_loading');
                form.submit();
            }
        });
    },
    messages: function () {
        $('.countto').countTo({
            from: 10,
            to: 0,
            speed: 10000
        });
    }
}

// On ready

document.addEventListener('DOMContentLoaded', function (event) {

    if ($('.only-numbers').length) {
        MyApp.onlyNumbers();
    }
    if ($('.only-letters').length) {
        MyApp.onlyLetters();
    }
    if ($('.only-alpha-numeric').length) {
        MyApp.onlyAlphaNumeric();
    }
    if ($('.only-phone').length) {
        MyApp.onlyPhone();
    }
    if ($('.only-date').length) {
        MyApp.onlyDate();
    }

    if ($('.page-form').length) {
        MyApp.form();
    }

    if ($('.countto').length) {
        MyApp.messages();
    }
});